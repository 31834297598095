// import logo from './logo.svg';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    window.location.href = "https://account.prembly.com"
  }, [])
  
  return (
    <div className="App">
    </div>
  );
}

export default App;
